.select-options {
    background: #F5F5F5;
    padding: 10px 15px 4px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.select-options:last-child { margin-bottom: 0;}

.input-options label, .select-options label {
    font-size: 13px;
    color: #787D8B;
    font-weight: 400;
    letter-spacing: .5px;
    margin-bottom: 1px;
    height: 15px;
    line-height: 15px;
}
.input-options {
    background: #F5F5F5;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.input-options textarea {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    margin-top: 10px;
    color: #333;
    font-size: 16px;
    height: 120px;
}
.input-options textarea::placeholder{
    color: #B1B5BB;
}
.form-submit-btn {
    border: none;
    width: 100%;
    background: #0A0914;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: .5px;
    height: 55px;
    line-height: 55px;
    transition: .3s ease;
    cursor: pointer;
}
.form-submit-btn:hover {
    background: #2F2D3F;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
}
.input-options input {
    width: 100%;
    padding: 10px 0px;
    border: none;
    background: transparent;
    color: #333;
    font-size: 16px;
}
.input-options input::placeholder{
    color: #B1B5BB;
}

.formsCallingBtn {
    width: 100%;
    height: 67px;
    line-height: 67px;
    font-size: 17px;
    background: #626be7;
    border-radius: 10px 10px 0 0;
    color: #fff;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.formsMuteCallingBtn {
    width: 100%;
    height: 51px;
    line-height: 51px;
    background: #525ac8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
    font-size: 12px;
    color:#fff;
}
.formsCallingBtn:hover, .formsMuteCallingBtn:hover {
    background: #2F2D3F;
}
.formsMuteCallingBtn ion-icon {
    font-size: 15px;
    margin-right: 7px;
}