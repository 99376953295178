.signInComponent {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
}
.signInComponent > svg {
    position: absolute;
    bottom: 32px;
    left: 58px;
    z-index:1;
    transform: scale(.9) translate(-50px, 50px);
}

.signInComponent > div {
    width: 500px;
    background: #fff;
    border-radius: 12px;
    padding: 40px 10px 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.05);    
}

.signInComponent > div svg:nth-child(2){
    margin-top: 20px;
}
.signInComponent > div form .errorMsg {
    color: #EB6477;
    font-size: 16px;
    width: 100%;
    height: 19px;
    line-height: 19px;
    margin: 10px 0;
    text-align: center;
}
.signInComponent > div .copyright {
    margin-top: 30px;
    font-size: 14px;
    color: #0A0914;
    width: 100%;
    text-align: center;
}

.signInComponent form {
    margin-top: 15px;
}

.signInComponent .setupLog {
    width: 100%;
    margin-top: 30px;
   background-color: #ececec;
   border-radius: 8px;
   font-size: 16px;
   text-align: center;
    padding: 20px;
}

.signInComponent .setupLog.error {
   background-color: #eb6477;
   color: #fff;
}

.signInComponent .setupLog {
    width: 100%;
    margin-top: 30px;
   background-color: #ececec;
   border-radius: 8px;
   font-size: 16px;
   text-align: center;
    padding: 20px;
    margin-bottom: 10px;
}

@media (max-width: 1100px){
    .signInComponent > svg {
        transform: scale(.7) translate(-160px, 160px);
    }
}

@media (min-width: 1101px) and (max-width: 1200px){
    .signInComponent > svg {
        transform: scale(.8) translate(-50px, 50px);
    }
}
@media (max-width: 500px){
    .signInComponent > div {
        width: 100%;
    }
}
