.modals {
    z-index: 99999;
    position: relative
}
.modals .modal1-bg {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
}

.modals .modal1-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    padding: 20px;
    background-color: #fff;
    overflow: visible;
    overflow-y: scroll;
    padding-bottom: 30px;
    padding-top: 30px;
}

.modals .modal-close {
    position: absolute;
    top: 10px;
    right: 410px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    background-color: #585858;
}
.modals .modal-close:hover {
    background-color: #EB6477;
    transform: scale(1.2);
}

.modals .modal-close svg {
    stroke: #fff;
    transition: all 0.3s ease-in-out;
}

.modals .modal-close:hover svg {
    stroke: #fff;
}

.modals .modal1-content .home-center-content1 {
    padding: 0;
}
.modals .modal1-content .home-center-content3 {
    padding: 0;
}